@import "../../themes";

.inputTextArea {
    resize: none !important;
    width: 100% !important;

    background: transparent !important;
    border-radius: 0px !important;

    &:focus {
        //background-color: #fafabe !important;
        outline: none !important;
        //box-shadow: 0 0 0 0 #8dcdff !important;
    }
    &:disabled {
        background-color: #dbdbdb !important;
        color: black !important;
        font-weight: bold !important;
    }
}

.errorField {
    background-color: #ffcdd2 !important;
    width: 100% !important;
    padding: 5px !important;
}

// :global(.p-inputtext) {
//     padding: .25em !important;
// }

:global(.inputtextarea) {
    width: 100% !important;
}
