.p-datatable-tbody > tr > td .p-column-title {
    display: none !important;
}

:global(#app[dir="rtl"]) {
    @media screen and (max-width: 40em) {
        .p-datatable.p-datatable-responsive {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
                width: 100% !important;
            }
            .p-datatable-scrollable-header,
            .p-datatable-scrollable-header-box,
            .p-datatable-scrollable-header-table {
                width: 100% !important;
            }
            .p-datatable-scrollable-colgroup {
                width: 100% !important;
            }
            .p-datatable-scrollable-colgroup > col {
                width: 100% !important;
            }
            .p-datatable-tbody > tr > td {
                text-align: right !important;
                display: block;
                width: 100% !important;
                float: right !important;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: 0.4rem;
                    min-width: 30%;
                    display: inline-block !important;
                    margin: -0.4em 1em -0.4em -0.4rem;
                    font-weight: bold;
                }

                &:last-child {
                    border-bottom: 1px solid var(--surface-d);
                    border-spacing: 1em !important;
                }
            }
        }
    }
}
:global(#app[dir="ltr"]) {
    @media screen and (max-width: 40em) {
        .p-datatable.p-datatable-responsive {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
                width: 100% !important;
            }
            .p-datatable-scrollable-colgroup {
                width: 100% !important;
            }
            .p-datatable-scrollable-colgroup > col {
                width: 100% !important;
            }
            .p-datatable-tbody > tr > td {
                text-align: left !important;
                display: block;
                width: 100% !important;
                float: left !important;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: 0.4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -0.4em 1em -0.4em -0.4rem;
                    font-weight: bold;
                }

                &:last-child {
                    border-bottom: 1px solid var(--surface-d);
                }
            }
        }
    }
}
