.dataview .object-name {
    font-size: 16px;
    font-weight: 700;
}

.dataview .object-description {
    margin: 0 0 1rem 0;
}

.dataview .object-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
}

.dataview .object-category {
    font-weight: 600;
    vertical-align: middle;
}

.dataview .object-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.dataview .object-list-item img {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-inline-end: 2rem;
    border-radius: 50% !important;
    cursor: pointer !important;
}

.dataview .object-list-item .object-list-detail {
    flex: 1 1 0;    
}

.dataview .object-list-item .p-rating {
    margin: 0 0 0.5rem 0;
}

.dataview .object-list-item .object-price {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
}

.dataview .object-list-item .object-list-action {
    display: flex;
    flex-direction: column;
    margin-inline-end: 6rem;
}

.dataview .object-list-item .p-button {
    margin-bottom: 0.5rem;
}

.dataview .object-grid-item {
    margin: 0.5em;
    border: 1px solid #dee2e6;
    padding: 1rem;
}

.dataview .object-grid-item .object-grid-item-top,
.dataview .object-grid-item .object-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dataview .object-grid-item img {
    width: 150px;
    height: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 4px 0;
    border-radius: 50% !important;
    cursor: pointer !important;
}

.dataview .object-grid-item .object-grid-item-content {
    text-align: center;
}

.dataview .object-grid-item .object-price {
    font-size: 16px;
    font-weight: 600;
}

@media screen and (max-width: 800px) {
    .dataview .object-list-item {
        flex-direction: column;
        align-items: center;
    }

    .dataview .object-list-item img {
        width: 40%;
        margin: 4px 0;
        border-radius: 50% !important;
    }

    .dataview .object-list-item .object-list-detail {
        text-align: center;
    }

    .dataview .object-list-item .object-price {
        align-self: center;
    }

    .dataview .object-list-item .object-list-action {
        display: flex;
        flex-direction: column;
    }

    .dataview .object-list-item .object-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

/* Speed Dial*/
.speeddial-circle .p-speeddial-circle {
    /* top: calc(50% - 2rem);
    left: calc(50% - 2rem); */
}
.speeddial-circle .p-speeddial-semi-circle.p-speeddial-direction-up {
    left: calc(50% - 2rem);
    bottom: 0;
}
.speeddial-circle .p-speeddial-semi-circle.p-speeddial-direction-down {
    left: calc(50% - 2rem);
    top: 0;
}
.speeddial-circle .p-speeddial-semi-circle.p-speeddial-direction-left {
    right: 0;
    top: calc(50% - 2rem);
}
.speeddial-circle .p-speeddial-semi-circle.p-speeddial-direction-right {
    left: 0;
    top: calc(50% - 2rem);
}
.speeddial-circle .p-speeddial-quarter-circle.p-speeddial-direction-up-left {
    right: 0;
    bottom: 0;
}
.speeddial-circle .p-speeddial-quarter-circle.p-speeddial-direction-up-right {
    left: 0;
    bottom: 0;
}
.speeddial-circle .p-speeddial-quarter-circle.p-speeddial-direction-down-left {
    right: 0;
    top: 0;
}
.speeddial-circle .p-speeddial-quarter-circle.p-speeddial-direction-down-right {
    left: 0;
    top: 0;
}
 .p-speeddial-action {
    width: 2rem !important;
    height: 2rem !important;
    background: #495057;
    color: #fff;
}
/*.p-speeddial-button.p-button.p-button-icon-only {
    width: 1.5rem;
    height: 1.5rem !important;
    border-radius: 50% !important;
} */

/* Speed Dial Tooltip*/
.speeddial-tooltip .p-speeddial-direction-up.speeddial-left {
    left: 0;
    bottom: 0;
}
.speeddial-tooltip .p-speeddial-direction-up.speeddial-right {
    right: 0;
    bottom: 0;
}
.speeddial-delay .p-speeddial-direction-up {
    left: calc(50% - 2rem);
    bottom: 0;
}
.speeddial-mask-demo .p-speeddial-direction-up {
    right: 0;
    bottom: 0;
}
