@import "../../themes";

.progressSpinner {
    text-align: center !important;
    
    width: 100% !important;
    height: 100% !important;
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 9999 !important;    
    @include themify($themes) {
        background-color: rgba($color: slategray, $alpha: 0.7);
    }
}

.progressPos{
     //left: 43% !important;
     position: fixed !important;
     margin: auto !important;
     align-self: center !important;     
     top: 40% !important;
}

.progressVisible{
    display: block !important;
}

.progressHide{
    display: none !important;
}
