@import "../../themes";

.multiselect {
    width: 100% !important;
    // border-radius: 0 !important;
}

.errorField {
    background-color: #FFCDD2 !important;
    width: 100% !important;
    padding: 5px !important;
}

:global(#app[dir="rtl"]) {
    :global(.p-multiselect-filter-container) {
        margin-right: 10px !important;
        float: right !important;
        direction: rtl !important;
    }
    :global(.p-multiselect-trigger) {
        left: 0 !important;
        right: auto !important;
    }
}

:global(.p-multiselect) {
    outline: none !important;    
    width: 100% !important;
    min-width: 150px !important;
}

.panelClassRight {
    direction: rtl !important;
    :global(.p-multiselect-close) {
        margin-left: 0 !important;
    }
    :global(.p-multiselect-item .p-checkbox) {
        margin: 0 0.5em 0 0.5em !important;
    }
    :global(.p-multiselect-filter-container) {
        margin: 0 0.5em 0 0.5em !important;
    }
    :global(.p-multiselect-filter-container .p-inputtext) {
        padding-left: 2em !important;
        padding-right: 0.5em !important;
    }
    :global(.p-multiselect-filter-container .p-multiselect-filter-icon) {
        left: 0.5em !important;
        right: auto !important;
    }
}

.panelClassLeft {
    direction: ltr !important;
    :global(.p-multiselect-close) {
        margin-left: auto !important;
    }
    :global(.p-multiselect-item .p-checkbox) {
        margin: 0 0.5em 0 0 !important;
    }
    :global(.p-multiselect-filter-container) {
        margin: 0 0.5em 0 0.5em !important;
    }
    :global(.p-multiselect-filter-container .p-inputtext) {
        padding-left: 0.5em !important;
        padding-right: 2em !important;
    }
    :global(.p-multiselect-filter-container .p-multiselect-filter-icon) {
        left: auto !important;
        right: 0.5em !important;
    }
}
