@import "../../themes";

.messageBar {
}

:global(.p-messages) {
    // margin: 0px !important;
    // font-size: 14px !important;
    // font-weight: bold !important;
}
:global(.p-message-detail){
    width: 100% !important;
}
:global(#app[dir="rtl"]) {
    
    :global(.p-messages-close .p-link) {
        float: left !important;
        margin-left: 0 !important;
        margin-right: auto !important;
    }
}
// :global(#app[dir="ltr"]) {
//     :global(.p-messages-close .p-link) {
//         // margin-right: 0 !important;
//         // margin-left: auto !important;
//     }
// }

:global(.p-message-icon) {
    margin-inline-end: 5px !important;
}

:global(.p-message-detail) {
    font-size: 16px !important;
}

:global(.p-message) {
    border-radius: 3px !important;
}

:global(.p-message .p-message-wrapper) {
    padding: 0.4rem 1.5rem !important;
}
