@import "../../themes";

.card {
    
}

:global(.p-card-title) {
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    margin-bottom: 0.5rem !important;
}