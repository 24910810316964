@import "../../themes";

.label {
    margin-right: 5px !important;
    margin-left: 5px !important;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
    vertical-align: middle !important;
    display: inline-block !important;
}
