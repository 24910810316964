.oddRow {
    background-color: white !important;
}

.evenRow {
    background-color: #eee !important;
}

.card{
    border: 1px solid gray !important;
    height: 100% !important;
    display: table !important;
    width: 70vw !important;
    position:relative !important;
}
