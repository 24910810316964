@import "../../themes";

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$gutter: 0.5em;

.buttonBar {
    margin-top: 5px !important;
    background-color: #ffffff !important;
    border: 1px solid #d8d8dc !important;
    padding: 0.07em 1em !important;
    -moz-border-radius: 2px !important;
    -webkit-border-radius: 2px !important;
    border-radius: 2px !important;
    margin-inline-start: 1px !important;
    margin-inline-end: 1px !important;
}

.buttonAlign {
}
.filterButton {
}

.delete {
    :global(.p-menuitem-link) {
        span {
            color: red !important;
        }
    }
}

:global(#app[dir="ltr"]) {
    .buttonAlign {
        text-align: right !important;
    }
    .filterButton {
        float: right !important;
    }
}
:global(#app[dir="rtl"]) {
    .buttonAlign {
        text-align: left !important;
    }
    .filterButton {
        float: left !important;
    }
}

:global(.p-menuitem-text) {
    width: 100% !important;
    min-width: 120px !important;
}

.menuItem {
    width: 100% !important;
}
