.menuItem {
  width: 100% !important;
  margin-inline-start: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
}
.flexItemLeft {
  flex: 50%;
  padding: 7px !important;
}
.flexItemRight {
  flex: 50%;
  padding: 7px !important;
}
.flexPart {
  flex: 33%;
  padding: 7px !important;
}
.flexQuarter {
  flex: 25%;
  padding: 7px !important;
}

/* Responsive layout - makes a one column-layout instead of a two-column layout */
@media (max-width: 500px) {
  .flexItemRight,
  .flexItemLeft,
  .flexPart,
  .flexQuarter {
    flex: 100%;
  }
}
