.checkboxLabel {
    margin-left: 10px;
    margin-right: 10px;
}
.checkbox {
    margin-bottom: 5px !important;
}

.buttons {
    margin-top: 5px;
    width: 140px;
    min-height: 25px !important;
    display: flex;
    justify-content: space-between;
}
