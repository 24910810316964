@import "../../themes";

.calendar {
    //width: 120px !important;
    width: 100% !important;
    //margin-top: 5px;
    border-right: soild 1px !important;
    :global(.p-inputtext) {
        //padding: 0.65em !important;
        width: 100% !important;
        @include themify($themes) {
            //background-color: themed("controlBackColor") !important;
        }
        &:focus {
            //background-color: #fafabe !important;
        }
        &:disabled {
            background-color: #dbdbdb !important;
            color: black !important;
            font-weight: bold !important;
        }
    }
    :global(.p-button) {
        @include themify($themes) {
            // background-color: themed("button") !important;
            // color: themed("buttonText") !important;
        }
        &:hover {
            @include themify($themes) {
                // background-color: themed("buttonHover") !important;
                // color: themed("buttonTextHover") !important;
            }
        }
    }
}

.errorField {
    background-color: #ffcdd2 !important;
    width: 100% !important;
    padding: 5px !important;
}

:global(#app[dir="rtl"]) {
    :global(.p-datepicker-buttonbar) {
        text-align: left !important;
        direction: ltr !important;
    }

    :global(.p-datepicker-timeonly){
        direction: ltr !important;
        text-align: left !important;
    }
}
:global(#app[dir="ltr"]) {
    :global(.p-datepicker) {
        direction: ltr !important;
        text-align: left !important;
    }
    :global(.p-datepicker-timeonly){
        direction: ltr !important;
        text-align: left !important;
    }
}
