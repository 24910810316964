.timesRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .inputContainerStyle {
    flex: 1;
    margin-top: -10px !important;
  }
  
  .time {
    margin-inline-start: 0 !important;
  }
  
  .fixMenuButton {    
    min-width: 160px !important;
  }