@import "../../themes";

.input {
    width: 100% !important;
}

.upperCase {
    text-transform: uppercase !important;
}

.errorField {
    background-color: #ffcdd2 !important;
    width: 100% !important;
    padding: 5px !important;
}

:global(.p-inputtext) {
    width: 100% !important;
}

:global(.input) {
    width: 100% !important;
}
