@import "../../themes";

.dialog {
}

:global(.p-dialog-header) {
    background-color: whitesmoke !important;
    border-radius: 2px !important;
    min-height: 40px !important;
}

:global(.p-dialog-header-icons) {
    margin-inline-end: 10px !important;
}

:global(.p-dialog-title) {
    margin-inline-start: 15px !important;
}


:global(.p-dialog-header-icons){
    margin-top: 10px !important;
}
