.confirmation-content {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.p-invalid {
    color: red !important;
}

.p-d-block {
    display: block !important;
}

:global(.p-inputtext) {
    width: 100% !important;
}
