@import "../../themes";

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$gutter: 0.5em;

.buttonBarLookup {
    // padding: 20px;
    // padding-top: 0px;
    // padding-bottom: 0px !important;
    // width: 100% !important;
    // display: "flex" !important;

    margin-top: 5px !important;
    background-color: #ffffff !important;
    border: 1px solid #d8d8dc !important;
    padding: 0.07em 1em !important;
    -moz-border-radius: 2px !important;
    -webkit-border-radius: 2px !important;
    border-radius: 2px !important;
    margin-inline-start: 1px !important;
    margin-inline-end: 1px !important;

    :global(.p-menuitem .p-menuitem-link) {
        padding: 0 !important;
        padding: 3px 5px !important;
    }
}

.buttonAlign {
}

.delete {
    :global(.p-menuitem-link) {
        span {
            color: red !important;
        }
    }
    color: red !important;
}

.deleteButton {
    color: red !important;
}

.operationButtons {
}
.fixMenuButton {
    min-width: 160px !important;
}

:global(#app[dir="ltr"]) {
    .operationButtons {
        text-align: right !important;
    }
}
:global(#app[dir="rtl"]) {
    .operationButtons {
        text-align: left !important;
    }
}

:global(.p-menu) {
    //top: 10px !important;
}

:global(.p-menuitem-text) {
    width: 100% !important;
    min-width: 120px !important;
}

.menuItem {
    width: 100% !important;
    margin-inline-start: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
}


