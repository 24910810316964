@import "../../themes";

$dir: rtl;

.dropDown {
    width: 100% !important;
    // border-radius: 0 !important;
}

.errorField {
    background-color: #ffcdd2 !important;
    width: 100% !important;
    padding: 5px !important;
}
:global(.p-dropdown .p-dropdown-clear-icon) {
    padding-inline-start: 5px !important;
}
:global(.p-dropdown .p-dropdown-label) {
    padding-inline-start: 25px !important;
}
:global(.p-dropdown .p-placeholder) {
    padding-inline-start: 25px !important;
}

:global(.p-dropdown-filter-container) {
    width: 100% !important;
    direction: var(--dir, $dir);
}

:global(.p-dropdown) {
    width: 100% !important;
    min-width: 150px !important;
    outline: none !important;

    &:disabled {
        background-color: #dbdbdb !important;
        color: black !important;
    }
    :global(.p-inputtext) {
        &:disabled {
            background-color: #dbdbdb !important;
            color: black !important;
            font-weight: bold !important;
        }
    }

    :global(.p-dropdown.p-disabled) {
        background-color: #dbdbdb !important;
        color: black !important;
        :global(.p-dropdown-label) {
            color: black !important;
            font-weight: bold !important;
            font-size: 30px !important;
        }
    }
}

:global(.p-dropdown-item-empty) {
    visibility: visible !important;
}

:global(.p-dropdown) {
    min-width: auto !important;
    top: 0 !important;
}

.floatLabelWithColorStyle {
    font-weight: normal !important;
    position: absolute !important;
    pointer-events: none !important;
    left: 0.25em !important;
    top: 50% !important;
    margin-top: -2em !important;
    transition: 0.3s ease all !important;
    -moz-transition: 0.3s ease all !important;
    -webkit-transition: 0.3s ease all !important;
    line-height: 1 !important;

    @include themify($themes) {
        color: themed("tableHeader") !important;
    }
}

.floatLabelStyle {
    font-weight: normal !important;
    position: absolute !important;
    pointer-events: none !important;
    left: 0.25em !important;
    top: 50% !important;
    margin-top: -2em !important;
    transition: 0.3s ease all !important;
    -moz-transition: 0.3s ease all !important;
    -webkit-transition: 0.3s ease all !important;
    line-height: 1 !important;
}

:global(#app[dir="rtl"]) {
    :global(.p-float-label) {
        position: relative !important;
        display: flex !important;
        float: right !important;
        font-size: 14px !important;

        label {
            width: 100% !important;
            text-align: right !important;
            margin-left: -10px !important;
        }
    }
}

:global(.p-dropdown .p-inputtext) {
    border: 0px solid #a9a9ae;
}

:global(.p-dropdown-items-wrapper) {
    direction: var(--dir, $dir);
    width: 100% !important;
}

:global(.p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon)::before {
    content: "" !important;
}

