@import "../../themes";

.spinner {
    width: 100%;
}
.label {
}

.floatLabelWithColorStyle {
    @include themify($themes) {
        color: themed("tableHeader") !important;
    }
}
.errorField {
    background-color: #FFCDD2 !important;
    width: 100% !important;
    padding: 5px !important;
}
:global(.p-spinner) {
    width: 100% !important;
    :global(.p-inputtext) {
        width: 100% !important;
        //border: 0px;
        border-radius: 0px !important;
        width: 100% !important;
        @include themify($themes) {
            background-color: themed("controlBackColor") !important;
        }
        &:focus {
            background-color: #fafabe !important;
            outline: none !important;
            box-shadow: 0 0 0 0 #8dcdff !important;
        }
        &:disabled {
            background-color: #dbdbdb !important;
            color: black !important;
            font-weight: bold !important;
        }
    }
}

:global(#app[dir="rtl"]) {
    :global(.p-spinner-button) {
        right: auto !important;
        left: 0 !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
        border: 0px !important;
        background-color: transparent !important;
        height: 60% !important;

        border: 0px !important;
        background-color: transparent !important;

        pointer-events: unset !important;

        span {
            background-color: transparent !important;
        }
    }
    :global(.p-spinner-input) {
        padding-right: 5px !important;
    }
}

:global(#app[dir="ltr"]) {
    :global(.p-spinner-button) {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
        border: 0px !important;
        background-color: transparent !important;
        height: 60% !important;

        border: 0px !important;
        background-color: transparent !important;
    }
    :global(.p-spinner-input) {
        padding-right: 5px !important;
    }
}

:global(#app[dir="ltr"]) {
    :global(.p-spinner-input) {
        padding-right: auto !important;
    }
    :global(.p-spinner-input) {
        padding-right: 5px !important;
    }
}

:global(.p-spinner-button-icon) {
    background-color: transparent !important;
    @include themify($themes) {
        color: themed("button") !important;
    }
}
