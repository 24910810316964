.newsticker {
    min-width: 20%;
    /* padding: 0.5rem; */
    margin-bottom: 0.5rem;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 13px;
    text-decoration: none;
  }
  
  .newsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
  }
  
  .title {
    text-overflow: ellipsis;
    word-wrap: none;
    white-space: nowrap;
    max-width: auto;
    overflow: hidden;
  }
  
  .title:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .meta {
    font-size: 10px;
  }
  
  .icon {
    width: 25px;
  }
  
  .icon img {
    width: 100%;
  }
  