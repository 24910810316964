.imgCss {
    border: 1px solid black !important;
    min-height: 29px !important;
    height: 30px !important;
    min-width: 220px !important;
}

:global(.p-fileupload-choose) {
    width: 300px !important;
}
input[type="file"] {
    display: none;
}
