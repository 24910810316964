html, body{
    height: 100%;
  }
  
  a {
    color: white;
  }
    
  .long {
    width: 100%;
    background-color: #010e19;
  }
   
  .container {
    height: 400px;
  }
    
  .scroll {
    // opacity: 0.8 !important;
    // background-color: orange !important;
    text-align: center !important;
    width: 40px !important;
    height: 40px !important;
    //position: fixed !important;
    // bottom: 10px !important;
    // right: 10px !important;
    border-radius: 5px !important;
    border: none !important;
    cursor: pointer !important;

    // &:hover {
    //   opacity: 1;
    // }
  }
  
  .arrowup{
    color: white;
    position: absolute;
    top: 50%;
    left: 20%;
    margin-top: -9px;
    margin-left: -5px;
  }    