:global(.p-selectbutton) {
    :global(.p-button) {
        margin-inline-end: 10px !important;
        padding: 5px !important;
        height: 30px !important;
        color: #495057 !important;
        //transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
    }
}

:global(.p-selectbutton) {
    :global(.p-button.p-highlight) {
        color: #ffffff !important;
    }
}
:global(.p-buttonset) {
    :global(.p-button:first-of-type) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}
:global(.p-buttonset) {
    :global(.p-button:first-of-type) :not(:last-child){
    border-right: 0 none !important;
    }
}
