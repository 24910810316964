@import '../../../themes';

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$gutter: 0.5em;

.PermissionsRolesAccess {
  //padding: 20px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  @include themify($themes) {
    background-color: themed('background') !important;
  }
  // background-color: white !important;
}

.AddPermissionsRolesAccess {
  //padding: 20px;
  //padding-top: 0px;
  padding-bottom: 0px !important;
  //background-color: transparent !important;
}

.titleBar {
  //margin-top: 10px !important ;
  //text-align: center !important;
  //vertical-align: top !important;
  height: auto !important;
}

.title {
  margin-top: 10px !important;
  margin-right: 10px !important;
  //margin-bottom: 20px !important;
  //padding-top: 20px !important;
}

.similarCheck {
  cursor: pointer !important;
  @include themify($themes) {
    color: themed('button') !important;
  }
}

:global(#app[dir='ltr']) {
  .similarCheck {
    text-align: right !important;
  }
}
:global(#app[dir='rtl']) {
  .similarCheck {
    text-align: left !important;
  }
}

.oddRow {
  background-color: white !important;
}

.evenRow {
  background-color: #eee !important;
}

.card {
  border: 1px solid gray !important;
  height: 100% !important;
  display: table !important;
  width: 70vw !important;
  position: relative !important;
}
