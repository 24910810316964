@import "../../themes";

.input {
    width: 100% !important;    
}

:global(.p-inputtext) {
    //padding: 0.65em !important;
    width: 100% !important;
    @include themify($themes) {
        //background-color: themed("controlBackColor") !important;
    }
    &:disabled {
        background-color: #dbdbdb !important;
        color: black !important;
        font-weight: bold !important;
    }
}

.upperCase {
    text-transform: uppercase !important;
}

.errorField {
    background-color: #ffcdd2 !important;
    width: 100% !important;
    padding: 5px !important;
}

:global(.p-inputtext) {
    width: 100% !important;
}

:global(.input) {
    width: 100% !important;
}

input {
    line-height: 0.1 !important;    
}

:global(.p-inputgroup .p-inputgroup-addon) {
    padding: 0 !important;
}

input{
    &[id="file-upload"]{
        line-height: 1.1 !important;    
    }
}
