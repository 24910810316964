/* app */
*, *::before, *::after {
    /* box-sizing: border-box !important; */
}

/* customize the browser's scrollbar: */
*::-webkit-scrollbar {
	width: 6px !important;
	height: 6px !important;
}
*::-webkit-scrollbar-track {
	border-radius: 0.25rem !important;
	background: rgba(0, 0, 0, 0.1) !important;
}
*::-webkit-scrollbar-thumb {
	border-radius: 0.25rem !important;
	background: rgba(0, 0, 0, 0.2) !important;
}
*::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.4) !important;
}
*::-webkit-scrollbar-thumb:active {
	background: rgba(0, 0, 0, 0.9) !important;
}

html,
body {
	height: 100% !important;
}

body {
	background-color: #f7faff !important;
	font-size: 0.875rem !important;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif !important;
	padding: 0 !important;
	margin: 0 !important;
	display: flex !important;
	flex-direction: column !important;
}

h3 {
	font-size: 0.875rem;
	font-weight: 500;
	text-align: center;
}

h4 {
	font-size: 0.875rem;
	font-size: 0.75rem;
	font-weight: 400;
	min-width: 7rem;
}

.flex-row {
	display: flex  !important;
	flex-direction: row;
	align-items: center;
	padding: 0 1rem !important;
}

.button {
	cursor: pointer;
}

/* #app {
	flex: 1 1 auto;
	overflow: hidden;
} */

.container {
	display: flex !important;
	flex-direction: row !important;
	height: 100% !important;
	background: #f2f6fe !important;
}

.hr {
	width: 1px;
	height: 100%;
	position: relative;
	background: #e4ecfb;
	z-index: 1;
}
.hr::before {
	content: '';
	display: block;
	width: 1px;
	height: 100%;
	background-color: #f6f9fe;
}

.blank {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	justify-content: center;
	text-align: center;
}

.blank svg {
	width: 3rem;
	height: 3rem;
	margin-bottom: 1rem;
}

.content {
	flex: 1 1 auto  !important;
	overflow: auto !important;
	background-color: #f7faff !important;
	width: 100% !important;
	padding: 0rem !important;
	box-sizing: border-box !important;
}
@media only screen and (max-width: 811px) {
	.content {
		padding: 0.25rem  !important;
	}
}


/* dashboard and tiles */
.dashboard {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: space-between !important;
	margin: auto !important;
	height: 100% !important;
}

.table {
	margin: 0 !important;
	table-layout: fixed !important;
	width: 100% !important;
}

.table td {
	padding: 0.15rem 0.5rem !important;
	font-size: 0.75rem !important;
	white-space: nowrap !important;
	width: 1.5rem !important;
}

.table td:first-child {
	width: 4rem;
}

.table td:last-child {
	width: auto !important;
}

.tile {
	display: flex !important;
	flex-direction: column !important;
	flex: 0 0 auto !important;
	width: calc(25% - 1rem) !important;
	margin: 0.5rem !important;
	height: 40vh !important;
	max-height: calc(65% - 1rem) !important;
	overflow: hidden !important;
	background: white !important;
	page-break-inside: avoid !important; /* important when printing the dashboard */
	transition: all 250ms !important;
	border-radius: 0.5rem !important;
	box-sizing: border-box !important;
	box-shadow: 0 1px 2px rgba(55, 63, 66, 0.07), 0 2px 4px rgba(55, 63, 66, 0.07), 0 4px 8px rgba(55, 63, 66, 0.07),
		0 8px 16px rgba(55, 63, 66, 0.07), 0 16px 24px rgba(55, 63, 66, 0.07), 0 24px 32px rgba(55, 63, 66, 0.07) !important;
}
@media only screen and (max-width: 1599px) {
	.tile {
		width: calc(33.33% - 1rem) !important;
	}
}
@media only screen and (max-width: 1079px) {
	.tile {
		width: calc(50% - 1rem) !important;
	}
}
@media only screen and (max-width: 1023px) {
	.tile {
		width: calc(100% - 1rem) !important;
	}
}
@media only screen and (max-height: 800px) {
	.tile {
		max-height: 400px !important;
	}
}
.tile:last-child {
	flex: 1 1 auto !important;
}
.tile:hover {
	border-color: #adb7bd !important;
}
.tile.drag-over {
	border: 2px dashed #000 !important;
}

.tile .buttons {
	transition: all 250ms !important;
	opacity: 0 !important;
}
@media (hover: none) and (pointer: coarse) {
	.tile .buttons {
		opacity: 1 !important;
	}
}
.tile:hover .buttons {
	opacity: 1 !important;
}
.tile .buttons > span {
	padding: 0 0.5rem !important;
	cursor: pointer !important;
}
.tile.drag-over {
	border: 2px dashed #000 !important;
	background-color: rgba(0, 0, 0, 0.1) !important;
	transition: all 250ms !important;
}
.tile.drag-source {
	opacity: 0.4 !important;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
	background-color: rgba(145, 200, 248, 0.75) !important;
	transform: scale(0.9) !important;
	transition: all 250ms !important;
}

.tile .tile-container {
	border-bottom: 1px solid #e0e0e0 !important;
	padding: 0.75rem 1rem !important;
	display: flex !important;
	cursor: move !important;
}

.tile .tile-header {
	flex-grow: 1 !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
	padding: 0.125rem !important;
	opacity: 0.75 !important;
}

.tile .tile-content {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	flex-direction: column !important;
	flex: 1 1 auto !important;
	overflow: auto !important;
	height: 100% !important;
}

.tile .blank-tile {
	height: 100vh !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}

/* tile content */

.wj-flexgrid {
	border: none;
	height: 100%;
}

.wj-flexgrid .wj-cell {
	border-right: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 0.35rem 1rem;
	font-size: 0.8125rem;
}

.wj-flexchart {
	background: transparent !important;
	height: calc(100%) !important;
	width: 100% !important;
	border: none !important;
	padding: 1rem !important;
	margin: 0 !important;
	overflow: hidden !important;
}
.wj-radialgauge {
	width: 60% !important;
	max-width: 300px !important;
	padding: 1rem !important;
	overflow: hidden !important;
}
.wj-radialgauge .wj-value {
	font-size: 0.75rem !important;
	font-weight: 500 !important;
}

.wj-lineargauge {
	max-height: 1rem !important;
	width: 100% !important;
	overflow: hidden !important;
}

.wj-gauge .wj-face path {
	stroke: none !important;
}

.wj-ranges {
	opacity: 0.15 !important;
}
