@import "../../themes";

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$gutter: 0.5em;

.home {
    padding: 10px !important;
    align-items: center;
    justify-content: center !important;
}

.row {
    justify-content: center;
    margin: 5px !important;
}

.buttonStyle {
    min-width: 150px !important;
    margin: 5px;
    background: #fff;
    color: black;
    box-shadow: 2px 2px 0 #c4c4c4;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    padding-top: 8px;
    font-weight: bold !important;
    i {
        font-size: 30px;
        font-weight: bold !important;
    }

    &:hover {
        background: #e7e4e4 !important;
        color: black;
        overflow: visible;
    }
}

:global(.p-menuitem-text) {
    width: 100% !important;
    min-width: 120px !important;
}

.menuItem {
    width: 100% !important;
}
