@import "../../themes";

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$gutter: 0.5em;

.addFingerPrint {
    //padding: 10px !important;
    align-items: center;
    justify-content: center !important;
}

.title {
    //margin-top: 10px !important;
}

.customFileUpload {
    //border: 1px solid #ccc !important;
    display: inline-block !important;
    padding: 6px 12px !important;
    cursor: pointer !important;
}